import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';

const httpOptions:any = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  
  public hostName : string;
  public cmsUrls : any;

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath();
    this.cmsUrls = {
        'listAll' : this.hostName+'api/all-cms/',
        'getByKey' : this.hostName+'api/cms/',
        'getById' : this.hostName+'api/cms-by/',
        'update' : this.hostName+'api/cms'
    }
   }
  

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getAllCms() : Observable<any> {
    return this.http.get(this.cmsUrls.listAll)
    .pipe( tap( (res) => res));
  }

  getCmsByKey(data) : Observable<any> {
    let getPath =  this.cmsUrls.getByKey+data;
    return this.http.get(getPath)
    .pipe( tap( (res) => res));
  }

  getCmsById(data) : Observable<any> {
    let getPath =  this.cmsUrls.getById+data;
    return this.http.get(getPath)
    .pipe( tap( (res) => res));
  }


  updateCmsById(data):Observable<any> {
    return this.http.put<ResponseData>(this.cmsUrls.update, data).pipe(
      tap((res) => res));
  }
}


export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}