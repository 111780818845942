import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class CommonService {
  
  private itemsLoading:any;
  public serverPath : string = `${environment.apiUrl}`;
  //public serverPath : string = "http://allround-express-delivery.nl:8090/";
  //public serverPath : string = "http://lscr.nl:8090/";
  public roles = [
    '', 'Klant', 'Medewerker', 'Beheerder'
  ];

  public parcelTypes = [
    '',
    'Envelop',
    'Colli',
    'Pallet',
    'Anders'
  ]

  public deliveryTimeType = [
    '',
    'Normaal',
    'Spoed',
    'Voor 09:00',
    'Voor 12:00',
    'Voor 17:00',
    'Anders'
  ];

  public  shipmentStatus = [
    '',
    'Nieuw',
    'Wacht op offerte',
    'Goedgekeurd',
    'In behandeling',
    'Afgerond',
    'Geannuleerd'
  ];

  get isItemsLoading() {
    return this.itemsLoading.asObservable();
  }

  constructor() {
    this.itemsLoading = new BehaviorSubject<boolean>(false);
   }  

  getServerPath() {
    return this.serverPath;
  }

  getRoles() {
    return this.roles;
  }

  getParcelTypes() {
    return this.parcelTypes;
  }

  getDeliveryTimeType() {
    return this.deliveryTimeType;
  }

  getShipmentStatus() {
    return this.shipmentStatus;
  }

  setItemsLoading(status : boolean) {
    this.itemsLoading.next(status);
  }
} 