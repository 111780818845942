import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {MatSidenav} from '@angular/material';
import { CmsService } from 'src/app/shared/services/cms.service';
import { ManagedimgsService } from 'src/app/shared/services/managedimgs.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @ViewChild(MatSidenav) drawer: MatSidenav;
  //@ViewChild('sidenav', { static : false }) sidenav: MatSidenav;
  reason = '';

  currentUrl : string;
  currentPage : String;
  introHeight  = '100vh';
  showHeader : Boolean = true;
  showMainSideBar : Boolean = false;
  contactHeader;
  introBanner = 'url(intro-banner.jpg)';
  
  constructor( 
    private router:Router, 
    private cmsService : CmsService,
    private managedImgsService : ManagedimgsService,
    private commonService : CommonService
  ) { }
  
ngOnInit() { 
  this.cmsService.getCmsByKey('contact_header').subscribe((response) => {
    if(response.success === true) {
      this.contactHeader = response.result;
    }
  });

  this.managedImgsService.getImgsByKey('banner').subscribe((response) => {
    console.log(response);
    if(response.success === true) {
      let bannerPath = this.commonService.getServerPath();
      let bannerName = response.result;
      bannerPath = bannerPath+'banners/'+bannerName.image;
      this.introBanner = ' url('+bannerPath+')';
    }
  });
}

ngDoCheck() {
  this.currentUrl = this.router.url;
  this.currentUrl = this.currentUrl.slice(1);
  let rootPage = this.currentUrl.split('/');
  this.currentPage = rootPage[0];
  if(rootPage[0] == 'master') {
    this.showHeader = false;
  }
  else {
    this.showHeader = true;
  }
  if(this.currentPage == 'home') {
    this.introHeight = '100vh';
  }
  else {
    this.introHeight = 'auto';
  }
}

toggleSidebar = () => {
  let rootPage = this.currentPage.split('/');
  if(rootPage[0] == 'master') {
    this.showMainSideBar = false;
  }
  if(this.currentPage == 'home') {
    this.introHeight = '100vh';
  }
  else {
    this.introHeight = 'auto';
  }
}

onToggleDrawerClick = () => {
  window.scroll(0,0);
  this.drawer.toggle();
} 

close(reason: string) {
  this.reason = reason;
  this.sidenav.close();
}

}

