import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss']
})
export class AdminSidebarComponent implements OnInit {
  public showMenu: string;
  public currentPage : String;
  public showSideBarNav : Boolean = false;
  isLoggedIn$: boolean;
  currentUserRole$: number;  
  currentUser;
  constructor(
    private router:Router,
    private authenticationService : AuthenticationService
  ) { }

  ngOnInit() {
    this.showMenu = '';
    this.currentUser = this.authenticationService.currentUserValue;
    this.isLoggedIn$ = this.authenticationService.loggedInValue;
    this.currentUserRole$ = this.authenticationService.loggedUserRole;
    console.log(this.currentUser); 
    console.log(this.isLoggedIn$);
    console.log(this.currentUserRole$);
  }
  
  ngDoCheck() {
    this.currentPage = this.router.url;
    this.currentPage = this.currentPage.slice(1);
    let rootPage = this.currentPage.split('/');
    this.currentUser = this.authenticationService.currentUserValue;
    this.isLoggedIn$ = this.authenticationService.loggedInValue;
    this.currentUserRole$ = this.authenticationService.loggedUserRole;
    if(rootPage[0] == 'master' && this.isLoggedIn$) {
      this.showSideBarNav = true;
    }
    else {
      this.showSideBarNav = false;
    }
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
        this.showMenu = '0';
    } else {
        this.showMenu = element;
    }
  }

  onLogout() {
    this.authenticationService.logout();
    this.router.navigate(['/master/login']); 
  }

}
