import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LogUser } from '../models/log-user.model';
import { CommonService } from './common.service';
import { environment } from '../../../environments/environment'; 
/* import { User } from '@app/_models'; */



@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<LogUser>;
    public currentUser: Observable<LogUser>;
    private loggedInSubject: BehaviorSubject<boolean>;
    private  userRoleSubject:BehaviorSubject<number>;
    public loggedIn: Observable<boolean>;
    public userRole: Observable<number>;

    constructor(private http: HttpClient, private commonService : CommonService) {
        let user = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUserSubject = new BehaviorSubject<LogUser>(user);
        this.currentUser = this.currentUserSubject.asObservable();
        let isLog = (user !== null) ? true : false;        
        let role = (user !== null) ? user.role : 0;
        this.loggedInSubject = new BehaviorSubject<boolean>(isLog);
        this.userRoleSubject = new BehaviorSubject<number>(role);
        this.loggedIn = this.loggedInSubject.asObservable();
        this.userRole = this.userRoleSubject.asObservable();
    }

    public get currentUserValue(): LogUser {
        return this.currentUserSubject.value;
    }

    public get loggedInValue(): boolean {
        return this.loggedInSubject.value;
    }

    public get loggedUserRole(): number {
        return this.userRoleSubject.value;
    }

    login(email: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}api/login`, { email: email, password: password  })
            .pipe(map(user => {

                if(user.success === true) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    let currentUser = user.result[0];
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                    this.currentUserSubject.next(currentUser);
                    this.loggedInSubject.next(true);                
                    this.userRoleSubject.next(currentUser.role);
                }
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.loggedInSubject.next(false);                
        this.userRoleSubject.next(0);
        this.currentUserSubject.next(null);
    } 
}