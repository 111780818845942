import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentPage : String;
  showFooter : Boolean = true;
  constructor(private router : Router) { }

  ngOnInit() {
  }

  
  ngDoCheck() {
      this.currentPage = this.router.url;
      this.currentPage = this.currentPage.slice(1);
      let rootPage = this.currentPage.split('/');
      if(rootPage[0] == 'master') {
        this.showFooter = false;
      }
      else {
        this.showFooter = true;
      }
  }


}
