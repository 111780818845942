import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { CommonService } from './common.service';

const httpOptions:any = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class ManagedimgsService {

  public hostName : string;
  public Urls : any;

  constructor(private http: HttpClient,
    private commonService : CommonService) {
    this.hostName = this.commonService.getServerPath();
    this.Urls = {
      'listAll' : this.hostName+'api/managed-imgs-all',
      'managedimgs' : this.hostName+'api/managed-imgs',
      'managedimgskey' : this.hostName+'api/managed-imgs-key'
    }
  }
  

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  getAllImgsList() : Observable<any> {
    return this.http.get<ResponseData>(this.Urls.listAll)
    .pipe( tap( (res) => res));
  }

  getImgsById(id):Observable<any> {
    return this.http.get<ResponseData>(this.Urls.managedimgs+"/"+id).pipe(
      tap((res) => res));
  }

  getImgsByKey(key):Observable<any> {
    return this.http.get<ResponseData>(this.Urls.managedimgskey+"/"+key).pipe(
      tap((res) => res));
  }


  updateImgsById(data):Observable<any> {
    return this.http.put<ResponseData>(this.Urls.managedimgs, data).pipe(
      tap((res) => res));
  }

}


export interface ResponseData {
  success: boolean;
  message: string;
  result : any;
}