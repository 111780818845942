import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-admin-topnav',
  templateUrl: './admin-topnav.component.html',
  styleUrls: ['./admin-topnav.component.scss']
})
export class AdminTopnavComponent implements OnInit {
  public pushRightClass: string;
  public showTopBarNav : boolean = false;
  public currentPage : String;
  public isLoggedIn$: boolean;
  public isItemsLoading$: boolean;
  public currentUser;
  currentUserRole$: number; 

  constructor(
    private router: Router,
    private commonService : CommonService,
    private authenticationService : AuthenticationService 
  ) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
          this.toggleSidebar();
      }
   });
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.currentUser = this.authenticationService.currentUserValue;
    this.isLoggedIn$ = this.authenticationService.loggedInValue;
    this.currentUserRole$ = this.authenticationService.loggedUserRole;
  }

  ngDoCheck() {
    
    this.currentUser = this.authenticationService.currentUserValue
    this.isItemsLoading$ = this.commonService.isItemsLoading;
    this.currentPage = this.router.url;
    this.currentPage = this.currentPage.slice(1);
    let rootPage = this.currentPage.split('/');
    this.isLoggedIn$ = this.authenticationService.loggedInValue;
    this.currentUserRole$ = this.authenticationService.loggedUserRole;
    if(rootPage[0] == 'master' && this.isLoggedIn$ === true) {
      this.showTopBarNav = true;
    }
    else {
      this.showTopBarNav = false;
    }
    
  }
  

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
      const dom: any = document.querySelector('body');
      dom.classList.toggle(this.pushRightClass);
  }

  onLogout() {    
    this.authenticationService.logout(); 
    this.router.navigate(['/master/login']);
  }

}
