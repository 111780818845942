import { Component } from '@angular/core';
import * as $ from 'jquery';

import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aedFrontend';
  currentPage : String;
  containerClass : String = '';
  showHeader : Boolean = true;
  
  constructor( private router:Router) { }

  ngOnInit() {
    if ((".loader").length) {
      // show Preloader until the website ist loaded
      $(window).on('load', function () {
        $(".loader").fadeOut("slow");
      });
    }
  }
  
  ngDoCheck() {
    this.currentPage = this.router.url;
    this.currentPage = this.currentPage.slice(1);
    let rootPage = this.currentPage.split('/');
    if(rootPage[0] == 'master') {
      if(rootPage[1] !="login") {
        this.containerClass = 'main-container';
      }
      else {
        this.containerClass = 'main-container main-container-login';
      }
    }
    else {
      this.containerClass = '';
    }
  }
}
